import React from 'react'
import { Link } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthContext'
import { logout } from '../libs/auth'
import { isSuperAdmin, lowerCase } from '../utils'
import ficovenlogo from '../assets/logo/ficovenlogo.png'

function Navbar () {
  const {
    authState: { isAuthenticated, user }
  } = useAuthContext() 

  return (
    <nav className='navbar navbar-expand-md navbar-dark bg-dark'>
      <div className='container-fluid'>
        <Link className='navbar-brand' to={isAuthenticated ? '/admin' : '/'}>
          <img src={ficovenlogo} alt='NIN | Ficoven' className='navbar-logo'  width="150px"/>
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarNav'
          aria-controls='navbarNav'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarNav'>
          <ul className='navbar-nav ml-auto'>
            {isAuthenticated ? (
              <li className='nav-item dropdown'>
                <a
                  className='nav-link dropdown-toggle'
                  href='#'
                  id='navbarDropdownMenuLink'
                  role='button'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <i className='fa fa-user'></i> {user.name}
                </a>
                <div
                  className='dropdown-menu dropdown-menu-right'
                  aria-labelledby='navbarDropdownMenuLink'
                >
                  {isAuthenticated && (
                    <Link className='dropdown-item' to='/admin'>
                      Home
                    </Link>
                  )}
                  <Link className='dropdown-item' to='/profile'>
                    Profile
                  </Link>
                  {isSuperAdmin.includes(lowerCase(user.role)) && (
                    <Link className='dropdown-item' to='/register'>
                      Add New Admin
                    </Link>
                  )}
                  <Link
                    className='dropdown-item'
                    to='#'
                    onClick={() => logout()}
                  >
                    Logout
                  </Link>
                </div>
              </li>
            ) : (
              <li className='nav-item dropdown'>
                <a
                  className='nav-link dropdown-toggle'
                  href='#'
                  id='navbarDropdownMenuLink'
                  role='button'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <i className='fa fa-user'></i> {user.name}
                </a>
                <div
                  className='dropdown-menu dropdown-menu-right'
                  aria-labelledby='navbarDropdownMenuLink'
                >
                  <Link className='dropdown-item' to='/login'>
                    Login
                  </Link>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
