import {
  LOGIN_USER,
  UPDATE_PROFILE,
} from "../../actions/actions.auth";
import Axios from "../../config";
import { getErrorMessage } from "../../utils";
import Swal from 'sweetalert2'
import TokenService from '../../libs/token'
import { useAuthContext } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert'
import { useEffect } from 'react'

export const loginUser = async (body, dispatch) => {  
  try {
    const { data } = await Axios.post("/users/login", body);
    dispatch({ type: LOGIN_USER, payload: data }); 
    return { error: false, data, msg: "Login Successful" };
  } catch (error) {
    return { error: true, data: {}, msg: getErrorMessage(error) };
  }
};

export const registerUser = async (body, dispatch) => {
  try {
    const { data } = await Axios.post("/users/register", body);
    // dispatch({type:LOGIN_USER, payload:data})
    return { error: false, data, msg: "Registration Successful" };
  } catch (error) {
    return { error: true, data: {}, msg: getErrorMessage(error) };
  }
};

export const logout = async () => {
  try {
    if (localStorage.getItem("_f_user")) {
      const user = localStorage.getItem("_f_user");

      if (!user) return null;
      const _user = JSON.parse(user);
      await Axios.patch("/users/logout", { refreshToken: _user.refreshToken });
    }
    localStorage.removeItem("_f_user");
    window.location.href = "/";
  } catch (error) {}
};

export const checkAuthUser = () => {
  if (localStorage.getItem("_f_user")) {
    const user = localStorage.getItem("_f_user");
    if (!user) return null;
    return JSON.parse(user);
  }
  return null;
};

export const changePassword = async (body) => {
  try {
    const { data } = await Axios.patch("/users/update_password", body);
    return { error: false, data, msg: data.message };
  } catch (error) {
    return { error: true, data: {}, msg: getErrorMessage(error) };
  }
};

export const updateDetails = async (dispatch, body) => {
  try {
    const { data } = await Axios.patch("/users/update_details", body);
    dispatch({ type: UPDATE_PROFILE, payload: body });
    return { error: false, data, msg: data.message };
  } catch (error) {
    return { error: true, data: {}, msg: getErrorMessage(error) };
  }
};

export const editUser = async (body) => {
  try {
    const { data } = await Axios.patch("/users/edit_user", body);
    return { error: false, data, msg: data.message };
  } catch (error) {
    return { error: true, data: {}, msg: getErrorMessage(error) };
  }
};

export const updateUser = async (body) => {
  try {
    const { data } = await Axios.patch("/users/update_password", body);
    return { error: false, data, msg: data.message };
  } catch (error) {
    return { error: true, data: {}, msg: getErrorMessage(error) };
  }
};

export const testLink1234 = async () => {
  try {
    await Axios.get("/users/test_link_1234");
  } catch (error) {}
};

export const testLink4321 = async () => {
  try {
    await Axios.get("/users/test_link_4321");
  } catch (error) {}
};

// Custom hook to handle auto logout when the token expires
export const useAutoLogout = () => {
  const history = useHistory();
  // Destructure the authState object from the useAuthContext hook to extract isAuthenticated and isAuthenticating
  const {
    authState: { isAuthenticated, isAuthenticating, user }, //Destructures the authState object to extract isAuthenticated and isAuthenticating values.
    authDispatch // Destructure authDispatch from useAuthContext hook
  } = useAuthContext() // Use the useAuthContext hook to get the authentication state and dispatch function

  useEffect(() => {
    const interval = setInterval(() => {
      const expiryTime = user.accessTokenExpiry * 1000 // Convert seconds to milliseconds

      // Check if the expiry time has passed and the user is authenticated
      if (new Date().getTime() > expiryTime) {
        handleLogout()
      }
    }, 1000) // Check every second

    return () => clearInterval(interval) // Clean up interval on component unmount
  }, [authDispatch, history, user.accessTokenExpiry]) // Dependencies for useEffect

  // Function to handle logout
  const handleLogout = async () => {
    try {
      // Check if user data exists in local storage
      if (localStorage.getItem('_d_user')) {
        const user = localStorage.getItem('_d_user')

        // Parse user data if it exists
        if (!user) return null
        const _user = JSON.parse(user)
        // Send a patch request to logout the user
        await Axios.patch('/users/logout', { refreshToken: _user.refreshToken })
      }

      // Remove user data from local storage
      TokenService.removeUser()

      // Dispatch logout action
      authDispatch({ type: 'LOG_OUT' })

      // Display logout message
      Swal.fire({
        icon: 'warning',
        title: 'Session expired',
        text: 'Your session has expired. Please log in again.',
        showConfirmButton: true,
        confirmButtonColor: '#006400'
      }).then(() => {
        // Redirect to the login page
        history.push('/login')
      })
    } catch (error) {
      // console.log('Auto logout error', error)
      swal('Oops', getErrorMessage(error), 'error')
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Oops!',
      //   text: 'An error occurred during auto-logout',
      //   showConfirmButton: true,
      //   confirmButtonColor: '#006400'
      // })
    }
  }
}
