import './App.css'
import React, { useEffect, lazy, Suspense } from 'react'
import Navbar from './components/Navbar'
import { Route, Redirect, Switch } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css'
import ProtectedRoute from './views/ProtectedRoute'
import { logout } from './libs/auth'
import Swal from 'sweetalert2'
import { useAuthContext } from '../src/contexts/AuthContext'
import { useAutoLogout } from './libs/auth/index.js'

import '../node_modules/datatables.net/js/dataTables.min.js'
import '../node_modules/datatables.net-bs4/js/dataTables.bootstrap4.min.js'
import '../node_modules/datatables.net-buttons/js/buttons.html5.min.js'
import '../node_modules/datatables.net-buttons/js/buttons.print.min.js'
import '../node_modules/datatables.net-buttons/js/dataTables.buttons.min.js'
import '../node_modules/datatables.net-buttons/js/buttons.colVis.min.js'
import '../node_modules/datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js'
import '../node_modules/datatables.net-responsive-bs4/js/responsive.bootstrap4.min.js'
import '../node_modules/datatables.net-dt/js/dataTables.dataTables.min.js'
import '../node_modules/datatables.net-responsive/js/dataTables.responsive.min.js'

//using lazy
const Homescreen = lazy(() => import('./screens/Homescreen'))
const Registerscreen = lazy(() => import('./screens/Registerscreen'))
const Loginscreen = lazy(() => import('./screens/Loginscreen'))
const AllBookings = lazy(() => import('./screens/AllBookings'))
const AllServices = lazy(() => import('./screens/AllServices'))
const AllUsers = lazy(() => import('./screens/AllUsers'))
const AllCenters = lazy(() => import('./screens/AllCenters'))
const Profilescreen = lazy(() => import('./screens/Profilescreen'))
const Adminscreen = lazy(() => import('./screens/Adminscreen'))
const AddCenter = lazy(() => import('./screens/AddCenter'))
const AddService = lazy(() => import('./screens/AddService'))
const UserDetails = lazy(() => import('./screens/UserDetails'))
const EditService = lazy(() => import('./components/EditService'))
const EditCenter = lazy(() => import('./components/EditCenter'))
const AllPayments = lazy(() => import('./screens/AllPayments'))
const PaymentDetails = lazy(() => import('./screens/PaymentDetails'))
const EditUserProfile = lazy(() => import('./screens/EditUserProfile'))
const TestScreen = lazy(() => import('./screens/TestScreen'))
const Analytics = lazy(() => import('./screens/Analytics'))
const Bookingscreen = lazy(() => import('./screens/Bookingscreen'))

function App () {
  useAutoLogout() // A custom hook that checks the token's expiration time every second and logs out the user if the token is expired. It displays a SweetAlert notification and navigates the user to the login page.

  const {
    authState: { isAuthenticated }
  } = useAuthContext()

  // window scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  //   if (isAuthenticated) {
  //     setTimeout(function () {
  //       let timerInterval
  //       Swal.fire({
  //         title: 'Auto Logout alert!',
  //         html:
  //           'You would be logged out in <strong></strong> seconds.<br/><br/>' +
  //           '<button id="stop" class="btn btn-danger">' +
  //           'Logout!!' +
  //           '</button>',
  //         allowEscapeKey: false,
  //         allowOutsideClick: false,
  //         timer: 10000,
  //         didOpen: () => {
  //           const content = Swal.getHtmlContainer()
  //           const $ = content.querySelector.bind(content)

  //           const stop = $('#stop')

  //           Swal.showLoading()

  //           function toggleButtons () {
  //             stop.disabled = !Swal.isTimerRunning()
  //           }

  //           stop.addEventListener('click', () => {
  //             logout()
  //             Swal.fire({
  //               title: 'Session Ended!',
  //               text: 'You have been logged out successfully',
  //               icon: 'success',
  //               confirmButtonColor: '#4CBB17'
  //             })
  //             Swal.stopTimer()
  //             toggleButtons()
  //           })

  //           timerInterval = setInterval(() => {
  //             Swal.getHtmlContainer().querySelector('strong').textContent = (
  //               Swal.getTimerLeft() / 1000
  //             ).toFixed(0)
  //           }, 100)
  //         },
  //         willClose: () => {
  //           logout()
  //           Swal.fire({
  //             title: 'Session Ended!',
  //             text: 'You have been logged out successfully',
  //             icon: 'success',
  //             confirmButtonColor: '#4CBB17'
  //           })
  //           clearInterval(timerInterval)
  //         }
  //       })
  //     }, 1800000) //1800000
  //   }
  // }, [isAuthenticated])

  return (
    <Suspense
      fallback={
        <div className='col text-center p-5'>
          <LoadingOutlined style={{ fontSize: 50, color: 'green' }} spin />
        </div>
      }
    >
      <div className='App'>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Homescreen} />
          <Route path='/login' exact component={Loginscreen} />
          <Route
            path='/book/:serviceid/:date'
            exact
            component={Bookingscreen}
          />
          <Route path='/test-link' exact component={TestScreen} />
          <ProtectedRoute
            path='/edit_service/:details'
            exact
            component={EditService}
          />
          <ProtectedRoute
            path='/edit_user/:details'
            exact
            component={EditUserProfile}
          />
          <ProtectedRoute
            path='/edit_center/:details'
            exact
            component={EditCenter}
          />
          <ProtectedRoute
            path='/payments/:details'
            exact
            component={PaymentDetails}
          />
          <ProtectedRoute path='/users/:user' exact component={UserDetails} />
          <ProtectedRoute path='/register' exact component={Registerscreen} />
          <ProtectedRoute path='/bookings' exact component={AllBookings} />
          <ProtectedRoute path='/analytics' exact component={Analytics} />
          <ProtectedRoute path='/profile' exact component={Profilescreen} />
          <ProtectedRoute path='/admin' exact component={Adminscreen} />
          <ProtectedRoute path='/services' exact component={AllServices} />
          <ProtectedRoute path='/users' exact component={AllUsers} />
          <ProtectedRoute
            path='/enrolment-centers'
            exact
            component={AllCenters}
          />
          <ProtectedRoute path='/payments' exact component={AllPayments} />
          <ProtectedRoute path='/add-service' exact component={AddService} />
          <ProtectedRoute path='/add-center' exact component={AddCenter} />
          <Redirect to='/' />
        </Switch>
      </div>
    </Suspense>
  )
}

export default App
