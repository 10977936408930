export const redirectUrl = "http://localhost:3000/verify_payment";

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getErrorMessage = (error) => {
  let message = error.message;
  if (error.response) {
    message = error.response.data.message;
  }
  return message.toString();
};

export const isEmpty = (obj) => {
  for (const key in obj) return false;
  return true;
};


export const lowerCase = (str) => {
  str = !str ? "" : str;
  return str.toString().toLowerCase();
};

export const isSuperAdmin = ["super", "admin"];

export const isSuper = ["super"];

export const checkSuperAdmin = (role) => {
  return isSuperAdmin.includes(lowerCase(role));  
}

export const uniqueNumber = () => {
  const rand = Math.random().toString();
  const randString = rand.split(".").toString().substring(2, 7);

  return Number(randString);
};

export const isUk = (country)=>{
  const _country = "united kingdom";
  return _country === country.toLowerCase();
}

export const isAsianCountry = (country) => {
  const asianCountries = [
    "china",
    "india",
    "japan",
    "south korea",
    "indonesia",
    "pakistan",
    "bangladesh",
    "philippines",
    "vietnam",
    "iran",
    "thailand",
    "myanmar",
    "south korea",
    "iraq",
    "afghanistan",
    "saudi arabia",
    "uzbekistan",
    "malaysia",
    "yemen",
    "nepal",
    "north korea",
    "sri lanka",
    "kazakhstan",
    "syria",
    "cambodia",
    "jordan",
    "azerbaijan",
    "united arab emirates",
    "tajikistan",
    "israel",
    "laos",
    "lebanon",
    "kyrgyzstan",
    "turkmenistan",
    "singapore",
    "oman",
    "state of palestine",
    "kuwait",
    "georgia",
    "mongolia",
    "armenia",
    "qatar",
    "bahrain",
    "timor-leste",
    "cyprus",
    "bhutan",
    "maldives",
    "brunei",
    "brazil",
    "mexico",
    "russia",
    "belarus",
    "estonia",
    "romania",
    "serbia",
    "lithuania",
    "colombia",
  ];

  return asianCountries.includes(country.toLowerCase());
};


export const numberWithCommas = (num)=>{
  return num?.toLocaleString('en-US') || '';
}
