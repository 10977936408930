import React, { useState, useEffect } from "react";
 
const BackToTopButton = () => {
    const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setBackToTopButton(true);
            } else {
                setBackToTopButton(false);
            }
        });
    }, []);

    const scrollUp = () => {
        window.scrollTo({
            top: 0, 
            behavior: "smooth",
        });
    };
    return (
        <div className="shadow-lg">
            {backToTopButton && (
                <button
                    style={{
                        position: "fixed",
                        bottom: "20px",
                        right: "20px",
                        background: "rgb(142, 223, 14)",
                        color: "white"
                    }}
                    onClick={scrollUp}
                    type="button"
                    className="btn shadow-lg btn-floating btn-lg"
                    id="btn-back-to-top"
                >
                    {/* <FontAwesomeIcon icon="fa fa-arrow-up" size="xs" /> */}
                    <i className="fa fa-arrow-up"></i>
                </button>
            )}
        </div>
    );
};

export default BackToTopButton;
